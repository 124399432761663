<template>
 <div class="bg-white">
  <NavBarNew></NavBarNew>
  <ProductsDescHero></ProductsDescHero>
  <ProductDescription></ProductDescription>
  <VideoSection></VideoSection>
  <ourProductsCarousel></ourProductsCarousel>
  <Popup></Popup>
  <Footer></Footer>
  </div>
</template>

<script>
import NavBarNew from "@/components/HomeScreen/NavBarNew.vue";
import ProductsDescHero from "@/components/ProductsDescriptionScreen/ProductsDescHero.vue";
import VideoSection from "@/components/ProductsDescriptionScreen/VideoSection.vue";
import ProductDescription from "@/components/ProductsDescriptionScreen/ProductDescription.vue";
import ourProductsCarousel from "@/components/HomeScreen/ourProductsCarousel.vue";
import Footer from "@/components/HomeScreen/Footer.vue";
import Popup from "@/components/base/Popup"

export default {
  name: "Products Description",
  components: {
    NavBarNew,
    ProductsDescHero,
    ProductDescription,
    VideoSection,
    ourProductsCarousel,
    Footer,
    Popup,
  },
};
</script>