<template>
  <div class="flex justify-center">
    <img src="./../../assets/images/VideoTemplate.png" alt="" />
  </div>
</template>

<script>
</script>


<style>
</style>